import './App.css'
import Ticker from './components/Ticker'
import CoinDeskPlug from './components/CoinDeskPlug'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

function App() {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <Ticker coin="bitcoin" />
            </QueryClientProvider>
            <CoinDeskPlug />
        </>
    )
}

export default App
