function CoinDeskPlug() {
    return (
        <div className="text-center">
            <a
                href="https://www.coindesk.com/price/bitcoin"
                target="_blank"
                rel="noreferrer"
                className="text-gray-600"
            >
                Powered by CoinDesk
            </a>
        </div>
    )
}

export default CoinDeskPlug
