import { useQuery } from 'react-query'

function Ticker({ coin }) {
    const coinEndpoints = {
        bitcoin: 'https://api.coindesk.com/v1/bpi/currentPrice.json',
    }

    const url = coinEndpoints[coin]

    const { isLoading, error, data } = useQuery(
        'priceData',
        () => fetch(url).then((res) => res.json()),
        { refetchInterval: 10 * 1000 }
    )

    if (isLoading) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    return (
        <div className="flex h-screen">
            <div className="m-auto mt-64 lg:mt-auto">
                <h1 className="font-karma text-white text-4xl lg:text-9xl p-6 px-12">
                    ${' '}
                    {data.bpi.USD.rate_float.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </h1>
            </div>
        </div>
    )
}

export default Ticker
